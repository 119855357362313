import './assets/css/reset.min.css'
import './assets/sass/index.sass'

import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'

Vue.config.productionTip = false
Vue.directive('tooltip', VTooltip)
Vue.directive('close-popover', VClosePopover)
Vue.component('v-popover', VPopover)

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')