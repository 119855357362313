import _ from 'lodash-es';
import Exhibit from './exhibit.js';
import StaggerMovement from '../utils/stagger-movement.js';

// Отновные цвета всего проекта
// TODO Делать в JSON ???
const colors = {
    yellow: "#FFDB07",
    yellow_bright: "#FFE454",

    red: "#FF0E10",
    red_bright: "#FF484A",

    blue: "#0710FF",
    blue_bright: "#6066FF"
};

// Все составляющие комнаты
// TODO Делать в JSON ???
const assets = window.assets = {
    gltfs: [
        { src: './gltf/exhibits/8_room.gltf' }
    ],
    jsons: [
        { src: './json/exhibits/8_room_light.json' }
    ],
    textures: [
        {}
    ],
    sounds: [
        {
            title: 'speakers_1',
            type: 'feedback',
            src: './sound/feedback/speakers_1.mp3',
            group_name: 'speakers_feedback',
        },
        {
            title: 'speakers_2',
            type: 'feedback',
            src: './sound/feedback/speakers_2.mp3',
            group_name: 'speakers_feedback',
        },
        {
            title: 'speakers_3',
            type: 'feedback',
            src: './sound/feedback/speakers_3.mp3',
            group_name: 'speakers_feedback',
        },
        {
            title: 'button_hover',
            type: 'feedback',
            src: './sound/feedback/button_hover.mp3',
        },
        {
            title: 'button_hit',
            type: 'feedback',
            src: './sound/feedback/button_hit.mp3',
        },
        {
            title: 'neon_bzz',
            type: 'feedback',
            src: './sound/feedback/neon_bzz.mp3',
        },
        {
            type: 'track',
            title: 'Hang Me from the Moon',
            src: './sound/tracks/Hang Me from the Moon_01.mp3',
            popup_id: 0,
            room_id: 8,
            popup_object_name: 'michro_pop-up'
        },
        {
            type: 'track',
            title: 'One Eye Open One Eye Closed - ArthurAutumn',
            src: './sound/tracks/One Eye Open One Eye Closed - ArthurAutumn_01.mp3',
            popup_id: 1,
            room_id: 8,
            popup_object_name: 'michro_pop-up'
        },
    ]
};

export default class Exhibit_8 extends Exhibit {
    constructor({ renderer, scene, camera, controls, manager, router }) {
        super({ renderer, scene, camera, controls, manager, router });
        this.assets = assets;
        const staggerCameraMovement = new StaggerMovement();    // Плавное покачивание камеры

        // Переход на роут комнаты
        // TODO Нужен более изящный переход на соотв. роут при инициализации комнаты
        router.push({ path: 'exhibit-8' }).catch(error => {
            if (error.name != "NavigationDuplicated") {
                throw error;
            }
        });


        (async () => {
            await this.preloadAssets(this.assets);                              // Загрузка составляющих комнаты

            this.assets.gltfs.forEach(object => scene.add(object.gltf.scene));  // Добавление всех загруженных gltf моделей в сцену
            this.assets.jsons.forEach(object => scene.add(object.json));        // Добавление всех загруженных json моделей в сцену

            scene.traverse(child => {                                           // Настройка положения камеры, света и т.д.
                // Отбрасывание теней для всех мешей кроме
                // тех что задействованы в UI
                if (
                    child.name !== 'michro_hitbox' &&
                    child.name !== 'logo_hitbox'
                ) {
                    child.castShadow = true;
                    child.receiveShadow = true;
                }

                if (child.isMesh) {
                    // FOR DEBUG
                    child.on('click', (event) => {
                        console.log(`${child.name}\n\nscene.getObjectByName('${child.name}')\n`, event);
                    });
                }

                if (child.isLight) {
                    if (child.type !== "AmbientLight" && child.type !== "PontLight") {
                        child.castShadow = true;
                        child.shadow.bias = -0.0002;            // Размытие тени
                        // child.shadow.mapSize.set(512, 512);
                        // child.power = 15;
                    }
                    // if (child.name === "Point_Orientation") {
                    //     child.intensity = 100;
                    //     console.log(child.power, child.name);
                    // }

                    // if (child.name === "SpotLight") {
                    //     child.intensity = 220;
                    //     child.power = 7;
                    //     console.log(child.power, child.name);
                    // }
                }

                if (child.name === "Camera") {                  // Хардкод позиции камеры
                    camera.position.set(-0.02, 2.14, 8.35);
                    camera.rotation.x = 0.02;

                    staggerCameraMovement.init(camera);         // Плавное покачивание камеры
                    staggerCameraMovement.start();
                }

            });

            // Обработчики событий
            this.addColorFeedbackOnHover(scene.getObjectByName('yellow_button'), colors.yellow_bright, "pointer");
            this.addColorFeedbackOnClick(scene.getObjectByName('yellow_button'), colors.yellow_bright);
            this.addColorFeedbackOnHover(scene.getObjectByName('red_button'), colors.red_bright, "pointer");
            this.addColorFeedbackOnClick(scene.getObjectByName('red_button'), colors.red_bright);
            this.addColorFeedbackOnHover(scene.getObjectByName('blue_button'), colors.blue_bright, "pointer");
            this.addColorFeedbackOnClick(scene.getObjectByName('blue_button'), colors.blue_bright);
            this.addRandomSoundFeedbackOnHover(scene.getObjectByName('kolonki'), _.filter(assets.sounds, { group_name: 'speakers_feedback' }), 0.5);

            this.addSoundFeedbackOnHover(scene.getObjectByName('red_button'), _.filter(assets.sounds, { title: 'button_hover' })[0].sound, 0.5);
            this.addSoundFeedbackOnHover(scene.getObjectByName('yellow_button'), _.filter(assets.sounds, { title: 'button_hover' })[0].sound, 0.5);
            this.addSoundFeedbackOnHover(scene.getObjectByName('blue_button'), _.filter(assets.sounds, { title: 'button_hover' })[0].sound, 0.5);

            this.addSoundFeedbackOnClick(scene.getObjectByName('red_button'), _.filter(assets.sounds, { title: 'button_hit' })[0].sound, 0.5);
            this.addSoundFeedbackOnClick(scene.getObjectByName('yellow_button'), _.filter(assets.sounds, { title: 'button_hit' })[0].sound, 0.5);
            this.addSoundFeedbackOnClick(scene.getObjectByName('blue_button'), _.filter(assets.sounds, { title: 'button_hit' })[0].sound, 0.5);

            this.addSoundPlayerOnHover(
                {
                    object: scene.getObjectByName('michro_hitbox'),
                    tracks: _.filter(assets.sounds, { type: 'track' }),
                    volume: 0.8,
                    color_animations: [
                        {
                            object_name: 'kolonki_2',
                            colors: [colors.blue, colors.red]
                        },
                        {
                            object_name: 'kolonki_3',
                            colors: [colors.red, colors.blue]
                        }
                    ]
                });

            this.addSoundFeedbackOnHoverHold(scene.getObjectByName('logo_hitbox'), _.filter(assets.sounds, { title: 'neon_bzz' })[0].sound, 0.5);

            this.addColorFeedbackOnHitboxHover(
                scene.getObjectByName('logo_hitbox'),
                scene.getObjectByName('logo_bloom_2'),
                colors.red,
                "pointer"
            );

            scene.getObjectByName('logo_hitbox').on('pointerdown', () => {
                router.push({ path: '/' })
                    .then(() => this.remove())
                    .catch(error => {
                        if (error.name != "NavigationDuplicated") {
                            throw error;
                        }
                    });
            });

        })();

        // Тикер комнаты
        this.tick = () => { staggerCameraMovement.update(); }
    }
}