export default function getScreenCoors(object, camera) {

    const width = window.innerWidth, height = window.innerHeight;
    const widthHalf = width / 2, heightHalf = height / 2;
    const pos = object.position.clone();

    pos.project(camera);

    return {
        x: (pos.x * widthHalf) + widthHalf,
        y: - (pos.y * heightHalf) + heightHalf
    }
}