<template>
  <div class="sound-button">
    <button @click="onClick">
      <span class="contact-block__icon" aria-hidden="true">
        <svg role="img" aria-hidden="true" width="30" height="30">
          <use
            v-if="$store.state.ambient_sound.paused"
            xlink:href="#svg-mute"
          ></use>
          <use v-else xlink:href="#svg-unmute"></use>
        </svg>
      </span>
    </button>
  </div>
</template>

<script>
import Sound from "@/modules/sound.js";

export default {
  beforeMount() {
    // Экземпляр Ambient звука
    this.sound = new Sound("./sound/ambient/ambience.mp3");
    this.sound.sound.volume = 0.75;
    // Воостановить настройки звука (из LocalStorage)
    this.$store.commit("ambient_sound/restoreSetting");
    if (this.$store.state.ambient_sound.paused === false) {
      window.addEventListener("click", () => this.sound.play(), {
        once: true,
      });
    }

    window.addEventListener("showPopup", () => {
      this.sound.sound.volume = 0.2;
    });

    window.addEventListener("hidePopup", () => {
      this.sound.sound.volume = 0.75;
    });
  },
  methods: {
    onClick() {
      const result = !this.sound.toggle(); //Возвращает [true/falsе => играет/пауза]
      this.$store.commit("ambient_sound/setPauseState", result);
    },
  },
};
</script>

<style lang="scss" scoped>
.sound-button {
  position: fixed;
  top: 10px;
  right: 10px;
  button {
    cursor: pointer;
    background: none;
    border: none;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
}
</style>