// import DevTools from "./dev/devtools";

// import * as THREE from 'three';
import { Interaction } from 'three.interaction';
import { setup } from './utils/setup.js';
import { loadHDR } from './utils/loaders.js';
import AnimationLoop from './utils/animation-loop.js';

import Exhibit_8 from "./exhibits/exhibit_8.js";

export default class Canvas {
	constructor(container, router) {
		const { renderer, scene, camera, manager } = setup(container);	// Сетап основных элементов
		const animation = new AnimationLoop();							// Основной Loop aнимации
		new Interaction(renderer, scene, camera);   					// Модуль интерактивности
		this.manager = manager;											// Manager как свойство экземпляра класса (для прелоадера)

		// const devtools = new DevTools({ container, renderer, scene, camera }); // Тулзы для отладки

		(async function () {
			await loadHDR('/hdr/studio_sm.hdr', renderer, scene, manager);

			// 8 комната
			const exhibit_8 = new Exhibit_8({ renderer, scene, camera, manager, router });

			animation.onTick = () => {
				exhibit_8.tick();

				//DEV
				// devtools.update();
				// devtools;
				//PROD
				renderer.render(scene, camera);
			}
		})();

	}
}
