<template>
	<div class="controls-container">
		<!-- <div class="room-navigation-buttons">
			<button>Start point</button>
			<button>Left point</button>
			<button>Right point</button>
			<button>Animation</button>
		</div> -->
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.room-navigation-buttons {
	position: fixed;
	bottom: 10px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	button {
		margin: 0 5px;
		cursor: pointer;
	}
}
</style>