import _ from 'lodash-es';
import getScreenCoors from '../utils/getScreenCoors.js';
import * as THREE from 'three';
import { loadGLTF, loadSound /*, loadHDR*/, loadJSON } from '@/modules/utils/loaders.js';
const TWEEN = require('@tweenjs/tween.js')
/**
 * Exhibits Parent Class
 */
export default class Exhibit {
    constructor({ renderer, scene, camera, controls, manager, router }) {
        // console.log('exhibit >>', renderer, scene, camera, controls, manager, router);  // DEBUG
        renderer, controls;

        this.scene = scene;
        this.camera = camera;
        this.manager = manager;
        this.router = router;
        this.assets = {};

        window.addEventListener('click', () => {
            const listener = new THREE.AudioListener();
            camera.add(listener);
            this.sound = new THREE.Audio(listener);
            this.audioplayer = new THREE.Audio(listener);
        }, { once: true });

        function animate(time) {
            requestAnimationFrame(animate)
            TWEEN.update(time)
        }
        requestAnimationFrame(animate)

    }

    /**
     * Метод загрузки ресурсов
     * Обрабатывает массивы объектов описывающих assets,
     * добавляя в каждый объект по факту загрузки модель/текстуру/звук
     */
    async preloadAssets() {
        const gltfs = this.assets.gltfs;
        const jsons = this.assets.jsons;
        const sounds = this.assets.sounds;
        // const textures = this.assets.textures; 

        const models_promises = [];
        const jsons_promises = [];
        const sounds_promises = [];
        // const textures_promises = [];

        // Promisify gltfs
        if (typeof gltfs === 'object' && gltfs.length) {
            gltfs.forEach(gltf => {
                if (!gltf.src) return;
                // Создать функцию возвращающую промис загрузки модели
                const load = async () => gltf.gltf = await loadGLTF(gltf.src, this.manager)
                // Сохранить возвращенный промис
                models_promises.push(load());
            });
        }

        // Promisify jsons
        if (typeof jsons === 'object' && jsons.length) {
            jsons.forEach(json => {
                if (!json.src) return;
                // Создать функцию возвращающую промис загрузки json
                const load = async () => json.json = await loadJSON(json.src, this.manager)
                // Сохранить возвращенный промис
                jsons_promises.push(load());
            });
        }

        // Promisify sounds
        if (typeof sounds === 'object' && sounds.length) {
            sounds.forEach(sound => {
                if (!sound.src) return;
                // Создать функцию возвращающую промис загрузки звука
                const load = async () => sound.sound = await loadSound(sound.src, this.manager)
                // Сохранить возвращенный промис
                sounds_promises.push(load());
            });
        }

        // Promisify textures
        // TODO ...

        // Реализовать все промисы
        await Promise.all([...models_promises, ...jsons_promises, ...sounds_promises]);
    }

    addColorFeedbackOnHover(object, color, cursor) {
        const initial_color = object.material.color.clone();
        const feedback_color = new THREE.Color(color);
        object.cursor = cursor ? cursor : 'default';
        object.on('pointerover', () => object.material.color.set(feedback_color));
        object.on('pointerout', () => object.material.color.set(initial_color));
    }

    addColorFeedbackOnHitboxHover(object1, object2, color, cursor) {
        const initial_color = object2.material.color.clone();
        const feedback_color = new THREE.Color(color);
        object1.cursor = cursor ? cursor : 'default';
        object1.on('pointerover', () => object2.material.color.set(feedback_color));
        object1.on('pointerout', () => object2.material.color.set(initial_color));
    }

    addColorFeedbackOnClick(object, color) {
        const initial_color = object.material.color.clone();
        const feedback_color = new THREE.Color(color);
        object.on('pointerdown', () => object.material.color.set(feedback_color));
        object.on('pointerup', () => object.material.color.set(initial_color));
    }

    addSoundFeedbackOnHover(object, buffer, volume) {
        object.on('pointerover', () => {
            if (buffer && this.sound) {
                if (this.sound.isPlaying)
                    this.sound.stop();
                this.sound.setBuffer(buffer);
                this.sound.setVolume(volume);
                this.sound.play();
            }
        });
    }

    addSoundFeedbackOnHoverHold(object, buffer, volume) {
        object.on('mouseover', () => {
            if (buffer && this.sound) {
                if (this.sound.isPlaying)
                    this.sound.stop();
                this.sound.setBuffer(buffer);
                this.sound.setVolume(volume);
                this.sound.play();
            }
        });

        object.on('mouseout', () => {
            if (buffer && this.sound) {
                if (this.sound.isPlaying)
                    this.sound.stop();
            }
        });
    }

    addSoundFeedbackOnClick(object, buffer, volume) {
        object.on('pointerdown', () => {
            if (buffer && this.sound) {
                if (this.sound.isPlaying)
                    this.sound.stop();
                this.sound.setBuffer(buffer);
                this.sound.setVolume(volume);
                this.sound.play();
            }
        });
    }

    addRandomSoundFeedbackOnHover(object, soundlist, volume) {
        object.on('pointerover', () => {
            if (typeof soundlist === 'object' && soundlist.length && this.sound) {
                const buffer = _.sample(soundlist).sound;
                this.sound.setBuffer(buffer);
                if (this.sound.isPlaying) return;
                this.sound.setVolume(volume);
                this.sound.play();
            }
        });
    }

    addSoundPlayerOnHover({ object, tracks, volume, color_animations }) {
        let index = 0;
        let timeout = 0;
        let animations = [];
        let tooltipPosition = {};
        let actualVolume = { x: 0 };
        const shuffledSounds = _.shuffle(tracks);
        // Инициализация анимаций
        color_animations.forEach(animation => {
            animations.push(this.createSwitchColorsAnimation(this.scene.getObjectByName(animation.object_name), animation.colors));
        });
        // Функция воспроизведения текущего трека
        const playTrack = () => {
            this.audioplayer.setBuffer(shuffledSounds[index].sound);
            this.audioplayer.play(); //console.log('Track: ' + shuffledSounds[index].title);
            window.dispatchEvent(new CustomEvent("showPopup", {
                detail: {
                    position: tooltipPosition,
                    id: shuffledSounds[index].popup_id
                }
            }));

            // По завершении текущего трека поставить следующий
            this.audioplayer.onEnded = () => {
                this.audioplayer.stop();
                index = index + 1 <= shuffledSounds.length - 1 ? ++index : 0;
                setTimeout(() => { playTrack() }, 300);
            };
        }

        object.on('mouseover', () => {
            if (typeof tracks === 'object' && tracks.length && this.audioplayer) {
                // Очистить таймаут стопа
                if (timeout) clearTimeout(timeout);
                // Установить кооринаты для tooltip
                tooltipPosition = getScreenCoors(this.scene.getObjectByName(tracks[0].popup_object_name), this.camera);
                // Стартовать все анимации 
                animations.forEach(animation => animation.start());

                //  Если не воспроизводится музыка
                if (this.audioplayer.isPlaying === false) {
                    playTrack();                  // Поставить трек
                    TWEEN.removeAll();            // Очистить эффект затухания
                    new TWEEN                     // Плавно нарастить громкость
                        .Tween(actualVolume).to({ x: volume }, 500)
                        .onUpdate(() => this.audioplayer.setVolume(actualVolume.x))
                        .start();
                }
                // Если воспроизводится музыка
                else {
                    TWEEN.removeAll();          // Очистить эффект затухания
                    new TWEEN                   // Плавно вернуть громкость
                        .Tween(actualVolume).to({ x: volume }, 500)
                        .onUpdate(() => this.audioplayer.setVolume(actualVolume.x))
                        .start();
                }

            }

        });

        object.on('mouseout', () => {
            if (this.audioplayer) {
                // Эффект затухания
                new TWEEN
                    .Tween(actualVolume).to({ x: 0 }, 2000)
                    .onUpdate(() => this.audioplayer.setVolume(actualVolume.x))
                    .start();

                timeout = setTimeout(() => {
                    this.audioplayer.pause();
                    animations.forEach(animation => animation.stop());
                    window.dispatchEvent(new CustomEvent("hidePopup", {
                        detail: { id: shuffledSounds[index].popup_id }
                    }));
                }, 2000);
            }
        });
    }

    createSwitchColorsAnimation(object, colors, delay) {

        function Animation(object, colors, delay) {
            let isRunning = false;
            object.material.emissiveIntensity = 0;
            const initial_color = object.material.color.clone();
            const animation_colors = colors.map((color) => new THREE.Color(color));
            let index = 0;
            setInterval(() => {
                if (isRunning) {
                    object.material.color.set(animation_colors[index]);
                    index = index < animation_colors.length - 1 ? ++index : 0;
                }
            }, delay || 400);

            this.start = () => {
                isRunning = true;
            }
            this.stop = () => {
                object.material.color.set(initial_color);
                isRunning = false;
            };
        }

        return new Animation(object, colors, delay);

    }

    // animateCameraToVector(camera, vector, target, time) {

    // }

    // animateCameraThroughVector(camera, firstVector, secondVector, target, time) {

    // }

    //TODO
    // Переписать возню выше, и вынести оттуда логику показа попапа
    showPopup() {

    }

    // TODO Дописать выгрузку из памяти и удаление загруженных объектов
    remove() {
        // this.assets.gltfs.forEach(object => {
        //     object.gltf.scene.dispose();
        // });
    }
}