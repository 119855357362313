export default {
    namespaced: true,
    state: () => ({
        paused: false,
    }),
    mutations: {
        setPauseState(state, paused) {
            state.paused = paused;
            if (localStorage) {
                localStorage.setItem("ambientSoundIsPaused", paused.toString());
            }
        },
        restoreSetting(state) {
            if (localStorage) {
                const paused = localStorage.getItem("ambientSoundIsPaused");
                // Если в LocalStorage есть запись с состоянием паузы
                if (paused && paused.length) {
                    state.paused = paused === "true";
                    // Если в LocalStorage нет такой записи, то создадим её
                } else {
                    this.commit('ambient_sound/setPauseState', false);
                }
            }
        },
    },
}
