import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';

function loadGLTF(path, manager) {
	return new Promise((resolve, reject) => {
		new GLTFLoader(manager)
			.load(path,
				gltf => {
					resolve(gltf);
				},
				progress => {
					progress;
					// console.log('Loading progress...', progress) 
				},
				error => { reject(); console.error('loadGLTF()', error); }
			);
	});
}

function loadHDR(path, renderer, scene, manager) {
	return new Promise((resolve, reject) => {
		const loader = new RGBELoader(manager);
		loader.setDataType(THREE.UnsignedByteType);

		loader
			.load(
				path,
				texture => {

					// PMREM GENERATOR
					const pmremGenerator = new THREE.PMREMGenerator(renderer);
					pmremGenerator.compileEquirectangularShader();

					// const envMap = pmremGenerator.fromEquirectangular(texture).texture;
					// this.scene.background = envMap;
					scene.environment = pmremGenerator.fromEquirectangular(texture).texture;

					texture.dispose();
					pmremGenerator.dispose();

					resolve(texture);
				},
				// called while loading is progressing
				function (xhr) {
					xhr;
					// console.log((xhr.loaded / xhr.total * 100) + '% HDR loaded');
				},
				// called when loading has errors
				function (error) {
					console.log('loadHDR()', error);
					reject(error);
				});
	});
}

function loadSound(path, manager) {
	return new Promise((resolve, reject) => {
		const loader = new THREE.AudioLoader(manager);

		loader.load(
			path,
			audioBuffer => {
				resolve(audioBuffer);
			},

			xhr => {
				xhr;
				// console.log((xhr.loaded / xhr.total * 100) + '% loaded');
			},

			error => {
				console.log('loadSound()', error);
				reject(error);
			}
		);

	});
}

function loadJSON(path, manager) {
	return new Promise((resolve, reject) => {
		const loader = new THREE.ObjectLoader(manager);
		loader.load(
			path,
			function (obj) {
				resolve(obj);
			},
			function (xhr) {
				xhr;
				// console.log((xhr.loaded / xhr.total * 100) + '% loaded');
			},
			function (err) {
				reject(); console.error('An error happened', err);
			}
		);
	});
}




export {
	loadGLTF,
	loadHDR,
	loadSound,
	loadJSON,
}