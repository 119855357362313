import Vue from 'vue'
import Vuex from 'vuex'

import preloader from '@/store/preloader.js'
import ambient_sound from '@/store/ambient_sound.js'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    preloader,
    ambient_sound
  }
})
