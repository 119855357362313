<template>
	<v-popover
		trigger="manual"
		:open="isOpen"
		offset="30"
		:auto-hide="false"
		:placement="'top'"
		:style="`position: absolute; left: ${position.x}px; top: ${position.y}px;`"
	>
		<button class="tooltip-target">Target</button>

		<template slot="popover">
			<h4>
				{{ popupContent.band }}
			</h4>
			<ul>
				<li>{{ popupContent.album }}</li>
				<li>{{ popupContent.title }}</li>
				<li>
					{{ popupContent.link.text }}
					<a :href="popupContent.link.href">{{ popupContent.link.href }}</a>
				</li>
				<li>
					<a :href="popupContent.clip.href">{{ popupContent.clip.text }}</a>
				</li>
			</ul>
		</template>
	</v-popover>
</template>

<script>
const popup_data = [
	{
		band: "BARBARIAN OVERLORDS - Los Angeles",
		album: "Album : RISE UP",
		title: "«Hang Me From The Moon»",
		link: { text: "Find the Band here!", href: "barbarianoverlordsmusic.com" },
		clip: {
			text: "YouTube",
			href: "https://www.youtube.com/watch?v=GU_FlQvEUJ0",
		},
	},
	{
		band: "ArthurAutumn - Los Angeles",
		album: "Album : Your Heartbeat Is Revolution",
		title: "«One Eye Open One Eye Closed»",
		link: { text: "Find ArthurAutumn @", href: "barbarianoverlordsmusic.com" },
		clip: {
			text: "YouTube",
			href: "https://www.youtube.com/watch?v=GU_FlQvEUJ0",
		},
	},
];

export default {
	data() {
		return {
			isOpen: false,
			popupContent: popup_data[0],
			position: {
				x: 0,
				y: 0,
			},
		};
	},
	mounted() {
		window.addEventListener("showPopup", (e) => {
			if (typeof e.detail.id === "number") {
				this.isOpen = true;
				this.popupContent = popup_data[e.detail.id];
				this.position = e.detail.position;
			}
		});

		window.addEventListener("hidePopup", () => {
			this.isOpen = false;
		});
	},
};
</script>

<style lang="scss">
.tooltip ul {
	padding-left: 20px;
}

.tooltip-target {
	position: absolute !important;
	visibility: hidden;
	pointer-events: none;
}

.tooltip {
	display: block !important;
	z-index: 10000;
	filter: drop-shadow(0px 0px 2px rgb(0, 0, 0));

	.tooltip-inner {
		background: rgba(0, 0, 0, 0.8);
		color: white;
		border-radius: 16px;
		padding: 15px 20px;
	}

	.tooltip-arrow {
		width: 0;
		height: 0;
		border-style: solid;
		position: absolute;
		margin: 5px;
		border-color: rgba(0, 0, 0, 0.8);
	}

	&[x-placement^="top"] {
		margin-bottom: 5px;

		.tooltip-arrow {
			border-width: 5px 5px 0 5px;
			border-left-color: transparent !important;
			border-right-color: transparent !important;
			border-bottom-color: transparent !important;
			bottom: -5px;
			left: calc(50% - 4.5px);
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	&[x-placement^="bottom"] {
		margin-top: 5px;

		.tooltip-arrow {
			border-width: 0 5px 5px 5px;
			border-left-color: transparent !important;
			border-right-color: transparent !important;
			border-top-color: transparent !important;
			top: -5px;
			left: calc(50% - 4.5px);
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	&[x-placement^="right"] {
		margin-left: 5px;

		.tooltip-arrow {
			border-width: 5px 5px 5px 0;
			border-left-color: transparent !important;
			border-top-color: transparent !important;
			border-bottom-color: transparent !important;
			left: -4.5px;
			top: calc(50% - 5px);
			margin-left: 0;
			margin-right: 0;
		}
	}

	&[x-placement^="left"] {
		margin-right: 5px;

		.tooltip-arrow {
			border-width: 5px 0 5px 5px;
			border-top-color: transparent !important;
			border-right-color: transparent !important;
			border-bottom-color: transparent !important;
			right: -4.5px;
			top: calc(50% - 5px);
			margin-left: 0;
			margin-right: 0;
		}
	}

	&[aria-hidden="true"] {
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.15s, visibility 0.15s;
	}

	&[aria-hidden="false"] {
		visibility: visible;
		opacity: 1;
		transition: opacity 0.15s;
	}
}
</style>