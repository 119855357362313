export default class StaggerMovement {
    constructor() {
        this.isRun = false;
    }

    init(camera) {
        this.camera = camera;
        this.camera.position.xNew = this.camera.position.x;
        this.camera.position.yNew = this.camera.position.y;
        let lastX = 0;
        let lastY = 0;

        // Quaternion
        this.camera.quaternion.xNew = this.camera.quaternion.x;
        this.camera.quaternion.yNew = this.camera.quaternion.y;
        let lastQX = 0;
        let lastQY = 0;

        window.addEventListener('mousemove', (e) => {
            let newX = (-(e.clientX / window.innerWidth) * 2 + 1) * 0.01;
            this.camera.position.xNew += lastX - newX;
            lastX = newX;

            let newY = ((e.clientY / window.innerHeight) * 2 + 1) * 0.018;
            this.camera.position.yNew += lastY - newY;
            lastY = newY;

            let newQX = ((e.clientY / window.innerHeight) * 2 - 1) * 0.018;
            this.camera.quaternion.xNew += lastQX - newQX;
            lastQX = newQX;

            let newQY = ((e.clientX / window.innerWidth) * 2 - 1) * 0.01;
            this.camera.quaternion.yNew += lastQY - newQY;
            lastQY = newQY;
        });
    }

    stop() {
        this.isRun = false;
    }

    start() {
        this.isRun = true;
    }

    update() {
        if (this.isRun) {
            // this.camera.quaternion.x += (this.camera.quaternion.xNew - this.camera.quaternion.x) * 0.05;
            this.camera.quaternion.y += (this.camera.quaternion.yNew - this.camera.quaternion.y) * 0.1;
            // this.camera.position.x += (this.camera.position.xNew - this.camera.position.x) * 0.08;
            this.camera.position.y += (this.camera.position.yNew - this.camera.position.y) * 0.12;
        }
    }
}