<template>
	<div ref="container" class="canvas-container"></div>
</template>

<script>
import Canvas from "@/modules/canvas.js";
export default {
	mounted() {
		// Экземпляр сцены
		this.canvas = new Canvas(this.$refs.container, this.$router);
		// Менеджер загрузки
		this.canvas.manager.onProgress = (url, itemsLoaded, itemsTotal) => {
			// Сообщение о % загруженных файлов
			this.$store.commit(
				"preloader/setPreloaderPercent",
				(100 / itemsTotal) * itemsLoaded
			);
			// console.log(`Loading: ${url}\nLoaded ${itemsLoaded} of ${itemsTotal} files`);
		};
	},
};
</script>


<style lang="scss" scoped>
.canvas-container {
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	/* background: #000 */
	max-width: 2560px;
	max-height: 1440px;
}
</style>