export default {
    namespaced: true,
    state: () => ({
        percent: 0,
        isHidden: false,
    }),
    mutations: {
        setPreloaderPercent(state, percent) {
            state.percent = Math.round(percent);
            if (state.percent >= 100) {
                setTimeout(() => {
                    // this.commit('preloader/hidePreloader');
                    // setTimeout(() => {
                    //     state.percent = 0;
                    // }, 300);
                }, 300);
            }
        },
        showPreloader(state) {
            state.isHidden = false;
        },
        hidePreloader(state) {
            state.isHidden = true;
        }
    },
}