<template>
  <div id="app">
    <Preloader />
    <Canvas />
    <Controls />
    <AmbientSound />
    <Tooltip />
    <SVGSprite />
  </div>
</template>

<script>
import Preloader from "./components/Preloader.vue";
import Canvas from "./components/Canvas.vue";
import Controls from "./components/Controls";
import AmbientSound from "./components/AmbientSound";
import Tooltip from "./components/Tooltip";
import SVGSprite from "./components/SVGSprite";

export default {
  name: "App",
  components: {
    Preloader,
    Canvas,
    Controls,
    AmbientSound,
    Tooltip,
    SVGSprite,
  },
};
</script>
