export default class AnimationLoop {
    constructor() {
        // this.functions = [];
        this.onTick = Function

        const animate = () => {
            // for (let index = 0; index < this.functions.length; index++) {
            //     this.functions[index]();
            // }
            this.onTick();
            requestAnimationFrame(animate);
        }
        animate();
    }

    // add(func) {
    //     this.functions.push(func);
    // }
}